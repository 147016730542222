import React, { useState, useEffect } from 'react';
import { FindGoogleUserResponseBody, ScrapeGoogleReviewsResponseBody } from 'contact-pro-shared';

export interface Props {
  email: string;
  onStateChange?: (state: GoogleSearchState) => void;
}

export interface GoogleSearchResults {
  user: FindGoogleUserResponseBody | null;
  reviews: ScrapeGoogleReviewsResponseBody | null;
}

export interface GoogleSearchState {
  user: FindGoogleUserResponseBody | null;
  reviews: ScrapeGoogleReviewsResponseBody | null;
  isLoading: boolean;
  hasSearched: boolean;
  errorMessage: string | null;
}

async function findUser(email: string): Promise<FindGoogleUserResponseBody | null> {
  const search = new URLSearchParams({ query: email });
  const response = await fetch(`/api/google/find-user?${search.toString()}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  });
  if (response.status === 204) {
    return null;
  } else if (response.status === 200) {
    return response.json() as Promise<FindGoogleUserResponseBody>;
  } else {
    throw new Error(`Unexpected ${response.status} response from find-user endpoint`);
  }
};

async function scrapeReviews(googleUserId: string): Promise<ScrapeGoogleReviewsResponseBody | null> {
  const response = await fetch(`/api/google/scrape-reviews/${googleUserId}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  });
  if (response.status === 204) {
    return null;
  } else if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(`Unexpected ${response.status} response from scrape-reviews endpoint`);
  }
}

export const GoogleSearch: React.FC<Props> = ({ email, onStateChange }) => {
  const [searchState, setSearchState] = useState<GoogleSearchState>({
    user: null,
    reviews: null,
    isLoading: false,
    hasSearched: false,
    errorMessage: null,
  });

  useEffect(() => {
    async function search() {
      if (!email) {
        return;
      }

      setSearchState({
        user: null,
        reviews: null,
        isLoading: true,
        hasSearched: true,
        errorMessage: null,
      });
      let user;
      try {
        user = await findUser(email);
      } catch (e) {
        return setSearchState({
          user: null,
          reviews: null,
          isLoading: false,
          hasSearched: true,
          errorMessage: (e as Error).message || `There was a problem searching Google for user ${email}`,
        });
      }
      if (!user) {
        return setSearchState({
          user: null,
          reviews: null,
          isLoading: false,
          hasSearched: true,
          errorMessage: null,
        });
      }

      let reviews;
      try {
        reviews = await scrapeReviews(user.googleUserId);
      } catch (e) {
        return setSearchState({
          user: null,
          reviews: null,
          isLoading: false,
          hasSearched: true,
          errorMessage: (e as Error).message || `There was a problem getting Google reviews for user ${email}`,
        });
      }

      return setSearchState({
        user,
        reviews,
        isLoading: false,
        hasSearched: true,
        errorMessage: null,
      });
    }
    search();
  }, [email]);

  useEffect(() => {
    onStateChange?.(searchState);
  }, [searchState, onStateChange]);

  return null;
};
