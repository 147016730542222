import React from 'react';
import { SearchFormState } from '../search-form-page';
import { Box, BoxProps } from '@mui/material';

export interface Props extends BoxProps {
  search: Partial<SearchFormState>;
}

export const DescriptionOfSearch: React.FC<Props> = ({ search, ...props }) => {
  const name = (() => {
    const nickname: string = search.nickname ? `"${search.nickname}"` : '';
    return [search.firstName, nickname, search.lastName]
      .filter(Boolean)
      .join(' ');
  })();
  const nameAndEmail = (() => {
    if (name && search.email) {
      return `${name} (${search.email})`;
    } else if (name) {
      return name;
    } else if (search.email) {
      return search.email;
    } else {
      return '';
    }
  })();
  return (
    <Box {...(props ?? {})}>
      {[nameAndEmail, search.phone, search.zip].filter(Boolean).join(', ')}
    </Box>
  )
};
