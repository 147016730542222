import React, { useMemo } from 'react';
import { useSession } from '../auth';
import { Box, Button } from '@mui/material';
import { Navigate } from 'react-router-dom';

export const ReactivatePage: React.FC = () => {
  const session = useSession();
  const subscriptionStatus = session?.['custom:subscription_status'];
  const message = useMemo(() => {
    switch(subscriptionStatus) {
      case 'canceled':
        return 'Your subscription has been canceled. You may reactivate it at any time.'
      case 'unpaid':
      case 'past_due':
        return 'Your subscription has been deactivated because your payment is past due. You may reactivate it at any time.';
      default:
        return 'Your subscription is inactive. You may reactivate it at any time.';
    }
  }, [subscriptionStatus]);
  
  if (!session || subscriptionStatus === 'active') {
    return (
      <Navigate to='/' replace={true} />
    );
  }

  // Lack of subscription status implies there is no subscription object on the stripe customer (this happens after the user cancels their subscription).
  // This means Stripe's 'portal' page won't show a subscription, and consequently won't avail any useful actions.
  // In this case, we do a new checkout instead.
  const formAction = !!subscriptionStatus ? '/api/billing/portal_session' : '/api/billing/checkout_session';

  return (
    <Box sx={{ textAlign: 'center', marginTop: '1em' }}>
      <div>{message}</div>
      <form action={formAction} method='POST'>
        <Button type='submit' sx={{ margin: '1em 0' }}>
          Reactivate your subscription
        </Button>
      </form>
    </Box>
  );
};
