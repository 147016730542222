import React from 'react';
import { FullBleedBackground } from './full-bleed-background';

export const TopBar: React.FC = ({ children }) => {
  return (
    <FullBleedBackground sx={{
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
    }}>
      {children}
    </FullBleedBackground>
  );
};
