import React, { useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { GoogleSearch, GoogleSearchState } from '../google-search';
import { YelpSearch, YelpSearchState } from '../yelp-search';
import { DescriptionOfSearch } from './description-of-search';
import { ReviewSummary } from './review-summary';
import { SearchFormState } from '../search-form-page';
import { useQuery } from '../utils';
import { ReviewList } from './review-list';
import { FullBleedBackground } from '../top-bar';
import { Breadcrumbs, Breadcrumb } from '../layout';

export const SearchResultsPage: React.FC = () => {
  const search = Object.fromEntries(useQuery().entries()) as Partial<SearchFormState>;
  const [googleSearchState, setGoogleSearchState] = useState<GoogleSearchState>({
    user: null,
    reviews: null,
    isLoading: false,
    hasSearched: false,
    errorMessage: null,
  });
  const [yelpSearchState, setYelpSearchState] = useState<YelpSearchState>({
    user: null,
    reviews: null,
    isLoading: false,
    hasSearched: false,
    errorMessage: null,
  });
  const handleGoogleSearchStateChange = useCallback((googleSearchState) => {
    setGoogleSearchState(googleSearchState);
  }, []);
  const handleYelpSearchStateChange = useCallback((yelpSearchState) => {
    setYelpSearchState(yelpSearchState);
  }, []);
  
  const isLoading = googleSearchState.isLoading || yelpSearchState.isLoading;

  if (!search.email) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb>Results</Breadcrumb>
      </Breadcrumbs>
      <GoogleSearch
        email={search.email ?? ''}
        onStateChange={handleGoogleSearchStateChange}
      />
      <YelpSearch
        email={search.email ?? ''}
        onStateChange={handleYelpSearchStateChange}
      />
      <FullBleedBackground sx={{ backgroundColor: 'rgba(66, 165, 345, 0.1)' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <DescriptionOfSearch search={search} sx={{ fontSize: '1.2em' }} />
            {isLoading ? (
              <Box sx={{ display: 'grid', gridTemplateColumns: '10em 5em', rowGap: '.5em' }}>
                <Box>Searching Google</Box>
                {googleSearchState.isLoading ? <CircularProgress size={20} /> : <CheckIcon color='success' />}
                <Box>Searching Yelp</Box>
                {yelpSearchState.isLoading ? <CircularProgress size={20} /> : <CheckIcon color='success' />}
              </Box>
            ) : (
              <ReviewSummary reviews={[...(googleSearchState.reviews ?? []), ...(yelpSearchState.reviews ?? [])]} />
            )}
          </Box>
        </Box>
      </FullBleedBackground>
      {!!googleSearchState.reviews?.length && (
        <ReviewList heading={`${googleSearchState.reviews.length} Google reviews`} reviews={googleSearchState.reviews} />
      )}
      {!!yelpSearchState.reviews?.length && (
        <ReviewList heading={`${yelpSearchState.reviews.length} Yelp reviews`} reviews={yelpSearchState.reviews} />
      )}
    </>
  );
};
