import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Review } from './review-list';
import { Doughnut } from 'react-chartjs-2';

export interface Props {
  reviews: Review[];
}

export interface Sentiment {
  text: string;
  explanation: string;
}

const Label = styled(Box)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
  fontSize: '.8em',
  fontWeight: 500,
}));

const Value = styled(Box)({
  fontSize: '2em',
});

const Supplement = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const ReviewSummary: React.FC<Props> = ({ reviews }) => {
  const googleReviewCount = reviews.filter(review => review.source === 'google').length;
  const yelpReviewCount = reviews.filter(review => review.source === 'yelp').length;
  const countsByRating: number[] = reviews.reduce((memo: number[], review: Review) => {
    if (review.rating !== null && review.rating !== undefined) {
      memo[review.rating] = memo[review.rating] + 1;
    }
    return memo;
  }, [0, 0, 0, 0, 0, 0]);
  const sentiment: Sentiment = (() => {
    const negativePercentage = (countsByRating[0] + countsByRating[1] + countsByRating[2]) / reviews.length;
    const middlePercentage = countsByRating[3] / reviews.length;
    const positivePercentage = (countsByRating[4] + countsByRating[5]) / reviews.length;
    if (positivePercentage > 0.9) {
      return {
        text: 'Overwhelmingly positive',
        explanation: 'Over 90% of reviews are four or five stars',
      };
    }
    if (positivePercentage > 0.5) {
      return {
        text: 'Mostly positive',
        explanation: 'Over half of reviews are four or five stars',
      };
    }
    if (negativePercentage > 0.9) {
      return {
        text: 'Overwhelmingly negative',
        explanation: 'Over 90% of reviews are one or two stars',
      };
    }
    if (negativePercentage > 0.5) {
      return {
        text: 'Mostly negative',
        explanation: 'Over half of reviews are one or two stars',
      };
    }
    if (middlePercentage > 0.5) {
      return {
        text: 'Mostly indifferent',
        explanation: 'Most reviews are three stars',
      };
    }
    return {
      text: 'Mixed',
      explanation: 'This reviewer gives a relatively even distribution of ratings',
    };
  })();
  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: '2em',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: {
            xs: 'space-between',
            sm: 'start',
          },
          gap: '2em',
        }}>
          <Box>
            <Label>Reviews written</Label>
            <Value>{reviews.length}</Value>
            <Supplement>{googleReviewCount} on Google, {yelpReviewCount} on Yelp</Supplement>
          </Box>
          <Box>
            <Label>Sentiment</Label>
            <Value>{sentiment.text}</Value>
            <Supplement>{sentiment.explanation}</Supplement>
          </Box>
        </Box>
        <Box sx={{
          maxWidth: {
            sm: '300px',
          },
        }}>
          <Label>Rating distribution</Label>
          <Box sx={{ marginTop: '.5em' }}>
            <Doughnut
              options={{
                layout: {
                  padding: -10,
                },
                plugins: {
                  tooltip: {
                    displayColors: false,
                  },
                  legend: {
                    position: 'top',
                    align: 'start',
                    labels: {
                      font: {
                        family: 'Roboto',
                      }
                    },
                  },
                },
              }}
              data={{
                labels: countsByRating
                  .map((count, n) => count > 0 ? `${n} star${n === 1 ? '' : 's'}` : null)
                  .filter(label => label !== null),
                datasets: [
                  {
                    label: 'ratings',
                    data: countsByRating.filter(count => count > 0),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.5)',
                      'rgba(54, 162, 235, 0.5)',
                      'rgba(255, 206, 86, 0.5)',
                      'rgba(75, 192, 192, 0.5)',
                      'rgba(153, 102, 255, 0.5)',
                      'rgba(255, 159, 64, 0.5)',
                    ],
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
