import React, { useCallback } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { SearchForm } from './search-form';

export const SearchFormPage: React.FC = () => {
  const navigate = useNavigate();
  const handleSubmit = useCallback((formState) => {
    navigate(`/search/results?${createSearchParams(formState)}`);
  }, [navigate]);

  return (
    <SearchForm onSubmit={handleSubmit} />
  );
};
