import React from 'react';

export interface Props {
  rating: number;
}

export const StarRating: React.FC<Props> = ({ rating }) => {
  return (
    <>
      {Array.from({ length: rating }, (element, index) => (
        <img src='/star.png' width={14} alt='' key={index} />
      ))}
      {Array.from({ length: 5 - rating }, (element, index) => (
        <img src='/star-empty.png' width={14} alt='' key={index} />
      ))}
    </>
  );
};
