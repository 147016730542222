import React, { useState, useCallback } from 'react';
import { Box, styled } from '@mui/material';
import { ScrapedGoogleReview, ScrapedYelpReview } from 'contact-pro-shared';
import { StarRating } from './star-rating';
import { FullBleedBackground } from '../top-bar';

export interface Props {
  heading: string;
  reviews: Review[];
}

export type Review = ScrapedGoogleReview | ScrapedYelpReview;

const TextButton = styled('button')(({ theme }) => ({
  '&:hover': { cursor: 'pointer' },
  border: 'none',
  backgroundColor: 'unset',
  padding: 0,
  fontFamily: 'roboto',
  textTransform: 'uppercase',
  color: theme.palette.grey['800'],
  fontSize: '.8em',
  fontWeight: 500
}));

export const ReviewList: React.FC<Props> = ({ heading, reviews }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleIsCollapsed = useCallback(() => {
    setIsCollapsed((prev: boolean) => !prev);
  }, []);

  return (
    <Box>
      <TextButton onClick={toggleIsCollapsed} sx={{ textAlign: 'left' }}>
        <FullBleedBackground sx={{ backgroundColor: 'rgba(66, 165, 345, 0.2)' }}>
          {heading}
          <span style={{ fontWeight: 'normal', paddingLeft: '0.5em' }}>{isCollapsed ? '[ + ]' : '[ - ]'}</span>
        </FullBleedBackground>
      </TextButton>
      <Box sx={{
        display: isCollapsed ? 'none' : 'grid',
        marginTop: '1em',
        marginBottom: '1em',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr 1fr',
        },
        gap: '2em',
      }}>
        {reviews?.map(review => (
          <Box key={review.reviewId} sx={{ display: 'flex', flexDirection: 'column', gap: '.5em' }}>
            <Box sx={{ fontWeight: 'bold' }}>{review.businessName}</Box>
            {review.rating !== null && review.rating !== undefined && (
              <Box>
                <StarRating rating={review.rating} />
              </Box>
            )}
            <Box>
              {review.reviewText ? (
                <div dangerouslySetInnerHTML={{ __html: review.reviewText }} />
                ) : (
                <Box sx={{ fontStyle: 'italic', color: 'text.secondary' }}>No comments</Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
