import React from 'react';
import { SearchFormPage } from '../search-form-page';
import { FullBleedBackground } from '../top-bar';

export const WelcomePage: React.FC = () => {
  return (
    <React.Fragment>
      <FullBleedBackground sx={{
        backgroundColor: 'rgba(66, 165, 345, 0.1)',
        textAlign: 'center',
        fontWeight: 600,
      }}>
        Welcome to ReviewSearch.io!
      </FullBleedBackground>
      <SearchFormPage />
    </React.Fragment>
  );
};
