import React from 'react';
import { Container, Box } from '@mui/material';

export const FullBleedBackground: React.FC<any> = ({ children, ...props }) => {
  return (
    <Box {...(props || {})} sx={{
      padding: '15px 0',
      width: '100vw',
      marginLeft: {
        xs: '-16px',
        sm: '-24px',
        lg: 'calc((-100vw + (1200px - 48px)) / 2)',
      },
      ...((props as any).sx || {}),
    }}>
      <Container>
        {children}
      </Container>
    </Box>
  );
};
