import { createTheme } from '@mui/material';

const defaultTheme = createTheme();
export const theme = createTheme({
  palette: {
    primary: {
      ...defaultTheme.palette.primary,
      contrastText: '#e2f2ff',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
  },
});
