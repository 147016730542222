import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSession } from '../auth';
import { Box, Button } from '@mui/material';

export const CompleteSignupPage: React.FC = () => {
  const session = useSession();
  const isSubscriptionActive = session?.['custom:subscription_status'] === 'active';

  if (isSubscriptionActive) {
    return (
      <Navigate to='/' replace={true} />
    );
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box sx={{ fontSize: '2em', marginTop: '1em', }}>Complete your signup</Box>
      <Box sx={{ color: 'text.secondary', marginTop: '1em', marginBottom: '1.5em' }}>Review the subscription plan below, then proceed to payment to complete your registration.</Box>
      <Box sx={{
        margin: '0 auto',
        maxWidth: '25em',
        border: '1px solid',
        borderColor: 'primary.dark',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
        padding: '15px',
        backgroundColor: 'rgba(66, 165, 345, 0.2)',
      }}>
        <Box sx={{ fontSize: '1.25em', fontWeight: 600, color: 'primary.dark' }}>
          Standard plan
        </Box>
        <Box>
          <Box sx={{ fontSize: '2em', fontWeight: 600 }}>$PRICE</Box>
          <Box sx={{ fontSize: '0.8em' }}>per month</Box>
        </Box>
        <Box>Unlimited monthly searches</Box>
        <form action='/api/billing/checkout_session' method='POST' style={{ display: 'contents' }}>
          <Button type='submit'>Proceed to payment</Button>
        </form>
      </Box>
    </Box>
  );
};
