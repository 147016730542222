import React, { useEffect } from 'react';
import { useSession } from './session-provider';

export interface Props {
  acceptableGroups?: string[];
}

export const RequireAuth: React.FC<Props> = ({ children, acceptableGroups }) => {
  const session = useSession();
  const isAuthorized = !session ?
    false :
    acceptableGroups ?
      Boolean(acceptableGroups.find(acceptableGroup => session?.['cognito:groups']?.includes(acceptableGroup))) :
      true;

  useEffect(() => {
    if (!isAuthorized) {
      window.location.href = '/api/auth/login';
    }
  }, [isAuthorized]);

  return (
    <React.Fragment>
      {isAuthorized ? children : null}
    </React.Fragment>
  );
};
