import React from 'react';
import { useSession } from './session-provider';
import { Navigate } from 'react-router-dom';

export const RequireActiveSubscription: React.FC = ({ children }) => {
  const session = useSession();
  const isNew = !session?.['custom:stripe_customer_id'];
  const isActive = session?.['custom:subscription_status'] === 'active';

  return (
    <React.Fragment>
      {isNew ? (
        <Navigate to='/complete_signup' replace={true} />
      ) : !isActive ? (
        <Navigate to='/reactivate' replace={true} />
      ) : (
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
