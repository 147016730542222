import { useState, ChangeEvent, SyntheticEvent } from 'react';
import { Box, TextField, Button } from '@mui/material';

export interface Props {
  onSubmit?: (formState: SearchFormState) => void;
}

export interface SearchFormState {
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  phone: string;
  zip: string;
}

export const SearchForm: React.FC<Props> = (props) => {
  const [formState, setFormState] = useState<SearchFormState>({
    firstName: '',
    lastName: '',
    nickname: '',
    email: '',
    phone: '',
    zip: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    props.onSubmit?.(formState);
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Box sx={{ flexGrow: 1, maxWidth: '500px' }}>
        <h2>Search</h2>
        <form onSubmit={handleSubmit}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1em',
          }}>
            <TextField required name='email' label='Email' value={formState.email} onChange={handleChange} />
            <TextField name='firstName' label='First name' value={formState.firstName} onChange={handleChange} />
            <TextField name='lastName' label='Last name' value={formState.lastName} onChange={handleChange} />
            <TextField name='nickname' label='Nickname' value={formState.nickname} onChange={handleChange} />
            <TextField name='phone' label='Phone' value={formState.phone} onChange={handleChange} />
            <TextField name='zip' label='Zip code' value={formState.zip} onChange={handleChange} />
            <Button type='submit'>Search Google and Yelp</Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
