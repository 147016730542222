import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { SearchFormPage } from './search-form-page';
import { SearchResultsPage } from './search-results-page';
import { CompleteSignupPage } from './complete-signup-page';
import { Layout } from './layout';
import { SessionProvider, RequireAuth, RequireActiveSubscription } from './auth';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { WelcomePage } from './welcome-page';
import { ReactivatePage } from './reactivate-page';

export function App() {
  return (
    <SessionProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<RequireAuth><Layout /></RequireAuth>}>
              <Route index element={<Navigate to='search' replace={true} />} />
              <Route path='complete_signup' element={<CompleteSignupPage />} />
              <Route path='reactivate' element={<ReactivatePage />} />
              <Route path='*' element={<RequireActiveSubscription><Outlet /></RequireActiveSubscription>}>
                <Route path='welcome' element={<WelcomePage />} />
                <Route path='search' element={<SearchFormPage />} />
                <Route path='search/results' element={<SearchResultsPage />} />
                <Route path='*' element={<Navigate to='/' replace={true} />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SessionProvider>
  );
}
