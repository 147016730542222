import React, { createContext, useEffect, useState, useContext } from 'react';
import type { CognitoIdToken } from 'contact-pro-shared';

export const SessionContext = createContext<CognitoIdToken | null>(null);

export function useSession() {
  return useContext(SessionContext);
}

export const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState<CognitoIdToken | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const response = await fetch(`/api/auth/me`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        setSession(responseJson);
      }
      setIsLoading(false);
    })();
  }, []);
  return (
    <SessionContext.Provider value={session}>
      {isLoading ? null : children}
    </SessionContext.Provider>
  );
};
