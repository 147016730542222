import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Outlet, Link, LinkProps } from 'react-router-dom';
import { TopBar } from './top-bar';
import { useSession } from './auth';

const BoldLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
}));

export const Breadcrumb: React.FC<Partial<LinkProps>> = ({ children, to, ...props }) => {
  return (
    <>
      <span style={{ paddingLeft: '.5em', paddingRight: '.5em' }}>&gt;</span>
      {to ? (
        <BoldLink to={to} {...(props ?? {})}>{children}</BoldLink>
      ) : (
        <span>{children}</span>
      )}
    </>
  );
}

export const Breadcrumbs: React.FC = ({ children }) => {
  const element = document.getElementById('breadcrumbs');
  if (!element) {
    return null;
  }
  
  return (
    ReactDOM.createPortal(
      children,
      element,
    )
  );
};

export const Layout: React.FC = () => {
  const theme = useTheme();
  const session = useSession();
  return (
    <Container>
      <TopBar>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <BoldLink to='/'>ReviewSearch.io</BoldLink>
            <span id='breadcrumbs' />
          </div>
          <Box sx={{ display: 'flex', gap: '1em' }}>
            {!!session?.['custom:subscription_status'] && (
              <form action='/api/billing/portal_session' method='POST'>
                <button type='submit' style={{
                  background: 'none',
                  border: 'none',
                  fontSize: '1em',
                  cursor: 'pointer',
                  color: theme.palette.primary.contrastText,
                  fontWeight: 'bold'
                }}>
                  Manage subscription
                </button>
              </form>
            )}
            <BoldLink reloadDocument to='/api/auth/logout'>Log out</BoldLink>
          </Box>
        </Box>
      </TopBar>
      <Outlet />
    </Container>
  );
};
